class NotificationsController {
  constructor(Notification, $timeout, WebNotificationService, _) {
    'ngInject';

    this.$timeout = $timeout;
    this.notificationService = Notification;
    this.webNotification = WebNotificationService;
    this._ = _;

    this.isOpened = false;
  }

  $onInit() {
    this.unreadCount = 0;

    this.notificationService.unreadCount(resp => {
      this.unreadCount = resp.count;
    });
  }

  onClick() {
    if (this.notifications === undefined) {
      setTimeout(() => {
        this.fetchNotifications();
      }, 1000);
    }
  }

  onMarkAllRead() {
    this.notifications = markAllAsRead(this.notifications);
    this.unreadCount = 0;
    this.notificationService.markAllRead();
  }

  onMarkOne($event) {
    if (!$event.hasOwnProperty('notification')) {
      return;
    }

    const notification = this._.find(this.notifications, {
      _id: $event.notification._id
    });

    if (!notification) {
      return;
    }

    notification.unread = !notification.unread;
    notification.readAt = new Date();

    this.notificationService
      .update({ id: notification._id }, notification)
      .$promise.then(notify => {
        this.onNotificationsChanged();
      })
      .catch(error => console.error(error));

    this.isOpened = false;
  }

  fetchNotifications() {
    this.notificationService.query(resp => {
      this.notifications = resp;
    });
  }

  onNotificationReceived(notification) {
    this.unreadCount++;

    if (this.notifications) {
      this.notifications.unshift(notification);
    }

    this.webNotification.showNotification(notification);
  }

  onNotificationsChanged() {
    this.unreadCount = getUnreadCount(this.notifications);
  }
}

angular.module('uvsApp').controller('NotificationsController', NotificationsController);

function markAllAsRead(messages = []) {
  return messages.map(message => {
    message.unread = false;
    return message;
  });
}

function getUnreadCount(notifies) {
  return notifies.reduce((count, notify) => {
    return notify.unread && !notify.readAt ? ++count : count;
  }, 0);
}
