'use strict';

angular
  .module('app.customers')
  .controller('CustomersCtrl', function($scope, customers, Customer, $state, _, Modal) {
    $scope.customers = customers;
    $scope.state = $state;
    $scope.searchQuery = {};

    $scope.del = Modal.confirm.delete(function(customer) {
      Customer.delete({ id: customer._id }, function() {
        _.remove($scope.customers, { _id: customer._id });
      });
    });

    $scope.customerListFilter = function(customer) {
      if ($scope.searchQuery.name) {
        return customer.name.toLowerCase().indexOf($scope.searchQuery.name.toLowerCase()) !== -1;
      }

      if ($scope.searchQuery.contactName) {
        return (
          customer.contacts[0] &&
          customer.contacts[0].name &&
          customer.contacts[0].name
            .toLowerCase()
            .indexOf($scope.searchQuery.contactName.toLowerCase()) !== -1
        );
      }

      if ($scope.searchQuery.contactPhone) {
        return (
          customer.contacts[0] &&
          customer.contacts[0].phone &&
          customer.contacts[0].phone
            .toLowerCase()
            .indexOf($scope.searchQuery.contactPhone.toLowerCase()) !== -1
        );
      }

      if ($scope.searchQuery.contactEmail) {
        return (
          customer.contacts[0] &&
          customer.contacts[0].email &&
          customer.contacts[0].email
            .toLowerCase()
            .indexOf($scope.searchQuery.contactEmail.toLowerCase()) !== -1
        );
      }

      return true;
    };
  })

  .controller('CustomerCtrl', function($scope, customer, Customer, $state, orders, titleService) {
    var vm = this;

    vm.customer = customer || new Customer();
    vm.isEditMode = vm.customer.hasOwnProperty('_id');
    vm.orders = orders;

    if (vm.isEditMode) {
      titleService.setTitle(customer.name);
    }

    vm.onSave = function(customer) {
      $state.go('root.customers.view', { id: customer._id });
    };
  });
