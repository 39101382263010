'use strict';

class OrdersRepository {
  constructor($q, _, orderClass, Order) {
    'ngInject';

    this.$q = $q;
    this._ = _;
    this.orderClass = orderClass;
    this.orderResource = Order;
    this.orders = [];
    this.tasks = [];
    this.totalItems = 0;
  }

  _setTotalItems(count = 0) {
    this.totalItems = count;
  }

  getOrders(options) {
    return this.orderResource.query(options, (orders, responseHeaders) => {
      this._setTotalItems(responseHeaders('Total-count'));
    }).$promise;
  }

  getTotalItems() {
    return this.totalItems;
  }

  getNewOrder(options) {
    return this.orderClass(options);
  }

  addTask(orderId, task) {
    const order = this._.find(this.orders, { _id: orderId });

    if (order) {
      order.tasks.push(task);
    }
  }

  deleteTask(orderId, task) {
    const order = this._.find(this.orders, { _id: orderId });

    if (order && order.tasks) {
      this._.remove(order.tasks, { _id: task._id });
    }
  }
}

angular.module('uvsApp').service('ordersRepository', OrdersRepository);
