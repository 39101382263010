'use strict';

angular
  .module('uvsApp', [
    // 'ngRaven',
    'ngCookies',
    'ui.router',

    'mobx-angularjs',

    'core',

    'app.estimates',
    'app.users',
    'app.customers',
    'app.tasks',
    'app.engagements',
    'app.invoices',
    'app.tenants',
    'components',

    'ngResource',
    'ngSanitize',
    'ngMessages',
    'ngAnimate',

    'ui.bootstrap',
    'as.sortable',
    'checklist-model',
    'ngQuill',
    'LocalStorageModule',
    'turn/stickyTableHeader',
    'ui.bootstrap.showErrors',
    'ui.calendar',
    'ui.select',
    'angular-loading-bar',
    'angular-clipboard',
    'smart-table',
    'toastr',
    'angularMoment',
    'formly',
    'formlyBootstrap',
    'angularScreenfull',
    'pageslide-directive',
    'ngFileUpload',
    'bootstrapLightbox',
    'uvsNotifications'
    // 'logglyLogger'
  ])
  .config(function(
    $stateProvider,
    $urlServiceProvider,
    $httpProvider,
    $animateProvider,
    toastrConfig,
    localStorageServiceProvider,
    LightboxProvider,
    ngQuillConfigProvider,
    $locationProvider
  ) {
    $locationProvider.html5Mode(true);
    $httpProvider.interceptors.push('authInterceptor');

    angular.extend(toastrConfig, {
      closeButton: true,
      maxOpened: 5
    });

    $stateProvider
      .state('simple', {
        url: '/login',
        abstract: true,
        template: '<div ui-view></div>'
      })
      .state('error404', {
        url: '/404',
        templateUrl: 'layouts/404.html'
      })
      .state('companySelect', {
        url: '/select-company',
        params: {
          component: 'companySelectModal',
          onClose: function() {}
        }
      })
      .state('companyCreate', {
        url: '/create-company',
        params: {
          component: 'companyCreateModal',
          company: null,
          onClose: function() {}
        }
      })
      .state('taskEdit', {
        url: '/task-edit',
        params: {
          component: 'taskModal',
          task: {},
          order: {},
          onClose: function() {}
        }
      })
      // Will always stop transition and show modal from new ui
      .state('openModal', {
        url: '/modal',
        params: {
          modal: null
        }
      })
      .state('root', {
        url: '',
        abstract: true,
        views: {
          '': {
            templateUrl: 'layouts/root.html',
            controller: 'AppController'
          },

          'header@root': {
            templateUrl: 'components/navbar/navbar.html',
            controller: 'NavbarCtrl'
          },

          'sidebar@root': 'ordersSidebar'
        },
        resolve: {
          user: function(Auth) {
            var u = Auth.getCurrentUser();

            if (u.hasOwnProperty('$promise')) {
              return u.$promise;
            }
          },
          appData: function(usersStore, departmentsStore, tenantsStore) {
            // Fetch application-wide used data
            return Promise.all([
              usersStore.fetchUsers(),
              tenantsStore.fetchTenant(),
              departmentsStore.fetchDepartments()
            ]);
          }
        }
      });

    $urlServiceProvider.rules.otherwise(function(matchValue, urlParts, router) {
      // console.log('%c UVS OTHERWISE', 'color: red', urlParts);
      /* eslint-disable-next-line */
      parent.postMessage({ event: 'navigateTo', data: { url: urlParts.path } }, '*');
    });

    localStorageServiceProvider.setPrefix('uvs');

    // TODO: change when moving to attachment component
    LightboxProvider.templateUrl = 'app/orders/directives/attachments/lightbox.html';
    LightboxProvider.getImageUrl = function(image) {
      return image.path;
    };

    ngQuillConfigProvider.set({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults
          [{ align: [] }],
          ['clean'] // remove formatting button
        ]
      },
      placeholder: ''
    });

    mobxLogger.enableLogging();

    $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
  })

  .constant('API_URL', '/api')

  .constant('APP_PERMISSIONS', {
    orderView: 'orderView',
    orderCreate: 'orderCreate',
    orderEdit: 'orderEdit',
    orderDelete: 'orderDelete',
    orderUpdateStatus: 'orderUpdateStatus',
    ordersOrder: 'ordersOrder',
    inventory: 'inventory'
  })

  .constant('UNITS', {
    m: 'm',
    m2: 'm\u00B2',
    m3: 'm\u00B3',
    sheet: 'lap.',
    kg: 'kg',
    piece: 'vnt.',
    ml: 'ml',
    l: 'l',
    h: 'h',
    min: 'min.',
    eur: 'EUR',
    cat: 'Kat.' // category
  })

  .constant('ASSOC_KINDS', {
    DEAL: 'Deal',
    CUSTOMER: 'Customer',
    CONTACT: 'Contact'
  })

  .factory('authInterceptor', function($rootScope, $q, $cookies, $location) {
    return {
      // Add authorization token to headers
      request: function(config) {
        config.headers = config.headers || {};
        if ($cookies.get('token')) {
          config.headers.Authorization = 'Bearer ' + $cookies.get('token');
        }
        return config;
      },

      // Intercept 401s and redirect you to login
      responseError: function(response) {
        if (response.status === 401) {
          $location.path('/login');
          // remove any stale tokens
          $cookies.remove('token');
          return $q.reject(response);
        } else {
          return $q.reject(response);
        }
      }
    };
  })

  .filter('setDecimal', function() {
    return function(input, places) {
      if (isNaN(input)) {
        return input;
      }
      // If we want 1 decimal place, we want to mult/div by 10
      // If we want 2 decimal places, we want to mult/div by 100, etc
      // So use the following to create that factor
      var factor = '1' + new Array(+(places > 0 && places + 1)).join('0');
      return Math.round(input * factor) / factor;
    };
  })

  .run(function(
    $rootScope,
    $transitions,
    $window,
    $location,
    Auth,
    APP_PERMISSIONS,
    amMoment,
    titleService
  ) {
    $rootScope.permissions = APP_PERMISSIONS;

    // If user is logged in go to orders, otherwise - login
    // But only when location path is / .
    // $stateChangeStart will take care of all other paths
    Auth.isLoggedInAsync(function(loggedIn) {
      if ($location.path() !== '/') {
        return;
      }

      if (!loggedIn) {
        return $location.path('/login');
      }
    });

    // Redirect to login if route requires auth and you're not logged in
    $rootScope.$on('$stateChangeStart', function(event, next) {
      Auth.isLoggedInAsync(function(loggedIn) {
        // Check if user is logged in when authentication required
        if (next.authenticate && !loggedIn) {
          $location.path('/login');
        }
        // Check if user has proper role if required
        // if (next.role && next.role !== Auth.getCurrentUserRole()) {
        //  $state.go('/orders');
        // }
      });
    });

    $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
      $rootScope.previousState = {
        name: from.name,
        params: fromParams
      };
    });

    $transitions.onSuccess({}, function(transition) {
      if (transition.to().title) {
        titleService.setTitle(transition.to().title);
      }

      /* eslint-disable-next-line */
      parent.postMessage(
        { event: 'navigateTo', data: { url: $location.url(), replace: true } },
        '*'
      );
    });

    amMoment.changeLocale('lt');
  })

  // TODO: do we really need this ???
  .run(function($rootScope, $state, $stateParams, $transitions, $urlService, Auth) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    $transitions.onError({ to: 'root.orders.view' }, function(transition) {
      if (transition._error.type === 5) {
        // The transition was ignored
        return;
      }
      $state.go('error404');
    });
  })

  .run(function($trace) {
    // $trace.enable();
  })

  // MODAL STATE HANDLER
  .run(function($transitions, $uibModal, $log, _) {
    $transitions.onBefore({}, function(transition) {
      // Open modal from new UI
      if (transition.to().name === 'openModal') {
        /* eslint-disable-next-line */
        parent.postMessage(
          { event: 'navigateTo', data: { url: '/modal/' + transition.params().modal } },
          '*'
        );
        return false;
      }
    });

    $transitions.onStart({ to: isModalState }, function(trans) {
      const component = trans.params().component;
      const resolvables = _.omit(trans.params(), 'component');

      const resolveObj = _.reduce(
        resolvables,
        function(rslObj, resolvable, key) {
          if (_.isFunction(resolvable) || resolvable == null) {
            return rslObj;
          }

          rslObj[key] = function() {
            return resolvable;
          };

          return rslObj;
        },
        {}
      );

      if (!component) {
        return false;
      }

      const modalInstance = $uibModal.open({
        component: component,
        resolve: resolveObj
      });

      modalInstance.result.then(
        function(value) {
          var onClose = trans.params().onClose;
          if (onClose && angular.isFunction(onClose)) {
            onClose(value);
          }
        },
        function() {
          $log.info('modal-component dismissed at: ' + new Date());
        }
      );

      return false;
    });
  });

function isModalState(state) {
  var modalStates = ['companySelect', 'companyCreate', 'taskEdit'];

  return modalStates.includes(state.name);
}
