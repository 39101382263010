class AppController {
  constructor(Auth, TaskRepository, ordersRepository, usersStore) {
    'ngInject';

    this.auth = Auth;
    this.tasksRepository = TaskRepository;
    // this.orders = orders;
    this.ordersRepository = ordersRepository;
    this.usersStore = usersStore;
  }
}

angular.module('uvsApp').controller('AppController', AppController);
